<template>
  <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.984 6.5C16.762 9.52901 14.492 12 12 12C9.50799 12 7.23403 9.52958 7.01597 6.5C6.78943 3.34896 8.99826 1 12 1C15.0017 1 17.2106 3.40625 16.984 6.5Z"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9997 14C7.01553 14 1.95687 17 1.02076 22.6625C0.907903 23.345 1.26195 24 1.91677 24H22.0827C22.7381 24 23.0921 23.345 22.9792 22.6625C22.0426 17 16.9839 14 11.9997 14Z"
      stroke="#363636"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <circle cx="24" cy="21" r="7" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 20.5C19 20.3295 19.0658 20.166 19.183 20.0455C19.3002 19.925 19.4592 19.8573 19.6249 19.8573L26.8654 19.8573L24.1819 17.0986C24.1238 17.0389 24.0777 16.9679 24.0463 16.8898C24.0148 16.8118 23.9987 16.7281 23.9987 16.6436C23.9987 16.559 24.0148 16.4754 24.0463 16.3973C24.0777 16.3192 24.1238 16.2483 24.1819 16.1885C24.24 16.1287 24.309 16.0813 24.3849 16.049C24.4608 16.0166 24.5422 16 24.6244 16C24.7065 16 24.7879 16.0166 24.8638 16.049C24.9397 16.0813 25.0087 16.1287 25.0668 16.1885L28.8164 20.0449C28.8746 20.1046 28.9208 20.1756 28.9523 20.2537C28.9838 20.3317 29 20.4155 29 20.5C29 20.5845 28.9838 20.6683 28.9523 20.7463C28.9208 20.8244 28.8746 20.8954 28.8164 20.9551L25.0668 24.8115C24.9495 24.9322 24.7903 25 24.6244 25C24.4584 25 24.2993 24.9322 24.1819 24.8115C24.0646 24.6908 23.9987 24.5271 23.9987 24.3564C23.9987 24.1858 24.0646 24.0221 24.1819 23.9014L26.8654 21.1427L19.6249 21.1427C19.4592 21.1427 19.3002 21.075 19.183 20.9545C19.0658 20.8339 19 20.6705 19 20.5Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheUserAttendIcon',
};
</script>

<style scoped></style>
